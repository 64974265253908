import React from 'react';
import { ScrollProvider } from './src/utils/hooks/use-scroll';
import { Script } from 'gatsby';
// TODO: How to inject third party scripts
// TODO: use PartyTown for this: https://www.gatsbyjs.com/blog/how-to-add-google-analytics-gtag-to-gatsby-using-partytown/#gatsby-skip-here
export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Script strategy="post-hydrate" id="pixel-facebook">
        {`!function(f,b,e,v,n,t,s)

{if(f.fbq)return;n=f.fbq=function(){n.callMethod?

n.callMethod.apply(n,arguments):n.queue.push(arguments)};

if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';

n.queue=[];t=b.createElement(e);t.async=!0;

t.src=v;s=b.getElementsByTagName(e)[0];

s.parentNode.insertBefore(t,s)}(window, document,'script',

'https://connect.facebook.net/en_US/fbevents.js');

fbq('init', '1332589144107612');

fbq('track', 'PageView');`}
      </Script>

      <ScrollProvider>{element}</ScrollProvider>
    </>
  );
};
