import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';

const ScrollContext = React.createContext({
  isTop: true,
  isScrollingDown: false,
  isPageYOffset400: false,
});

export const ScrollProvider = (props) => {
  const [scroll, setIsScrolled] = useState({
    isTop: true,
    isScrollingDown: false,
    isPageYOffset400: false, // scrolled down by at least 200 px
  });
  const position = useRef(0);

  const scrollListener = () => {
    let isDown = false;
    if (position.current < window.pageYOffset) {
      isDown = true;
    }
    if (window.pageYOffset > 5) {
      const isPageYOffset400 = window.pageYOffset > 400;

      setIsScrolled({
        isTop: false,
        isScrollingDown: isDown,
        isPageYOffset400,
      });
    } else {
      setIsScrolled({
        isTop: true,
        isScrollingDown: isDown,
        isPageYOffset400: false,
      });
    }
    position.current = window.pageYOffset;
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.addEventListener('scroll', scrollListener);
    window.addEventListener('load', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('load', scrollListener);
    };
  }, []);
  // avoid expensive rerender, only change value when something happens that is different
  // eslint-disable-next-line
  const memoized = useMemo(
    () => ({ ...scroll }),
    [scroll.isTop, scroll.isScrollingDown, scroll.isPageYOffset400]
  );

  return (
    <ScrollContext.Provider value={memoized}>
      {props.children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (context === undefined)
    throw new Error('You must use it within a ScrollContext provider');
  return context;
};
